import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import data from '../data/data';

const Collections = () => {
    return (
        <div className='collections'>
            <div className='layout-width'>
                <div className='collections-images'>
                    {
                        data.collections.map((collection, index) => {
                            return (
                                <NavLink to='/gallery' key={index}>
                                    <img src={require(`../images/${collection.image}`)} alt={collection.title} />
                                    <div className='collections-name'>
                                        <p>{collection.title}</p>
                                    </div>
                                </NavLink>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    )
}

export default Collections