import React, { useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import saiBoutiqueSmall from '../images/sai_boutique_small.svg';
import data from '../data/data';

const Header = () => {
    let [header, setHeader] = useState(false);
    let [pathName, setPathName] = useState();
    let [toggle, setToggle] = useState(false);

    useEffect(() => {
        if (window.location.pathname === '/') {
            setHeader(false);
        } else {
            setHeader(true);
        }
    }, [pathName]);
    
    let handleNavigation = () => {
        setPathName(window.location.pathname);
        setToggle(!toggle);
    }
    let handleHeaderToggle = () => {
        setToggle(!toggle);
        setHeader(true);
    }

    return (
        <div className={`header-container ${toggle ? 'visible' : ''}`}>
            <header className={`${header ? 'pink' : ''} `}>
                <div className='layout-width'>
                    <div className='sai-boutique-small'>
                        <a href='/'><img src={saiBoutiqueSmall} alt='Sai Boutique' /></a>
                    </div>
                    <ul>
                        {
                            data.menus.map((menu, index) => <li key={index}><NavLink onClick={handleNavigation} to={menu.link} title={menu.name}>{menu.name}</NavLink></li>)
                        }

                    </ul>
                </div>
            </header>
            <button tabIndex={1} className='icon-hamburger' onClick={handleHeaderToggle}><i className='fa fa-bars'></i></button>
        </div>
    )
}

export default Header