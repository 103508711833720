import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Layout from './Layout';
import Collections from './Collections'
import Testimonials from './Testimonials'

const Contact = () => {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta name="description"
                        content="Explore our women's boutique for exclusive fashion pieces and accessories. Elevate your wardrobe with styles celebrate individuality & elegance." />
                    <meta property="og:description" content="Explore our women's boutique for exclusive fashion pieces and accessories. Elevate your wardrobe with styles celebrate individuality & elegance." />
                    <meta name="google-site-verification" content="i1uy9Svtz05tBx270VhlF5TiZiOb3F_rPNNMJB12EZM" />
                    <link rel="canonical" href="http://saiboutique.me/contact" />
                    <title>Sai Boutique | Stylish Women's Clothing Boutique in Hosur</title>
                </Helmet>
            </HelmetProvider>
            <Layout>
                <div className='contact'>
                    <div className='page-header'>
                        <div className='layout-width'>
                            <h1>Feel free to contact me if you need any type of outfit made.
                                <div className='phone'><i className='fa fa-phone'></i>97916 77249</div>
                            </h1>
                            <img src={require('../images/contact.png')} alt='Sai Boutique' />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Contact