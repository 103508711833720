
import './css/saiboutique.css';
import { BrowserRouter, Routes, Route, HashRouter as Router } from 'react-router-dom';
import About from './components/About';
import Home from './components/Home';
import Header from './components/Header';
import Services from './components/Services';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Gallery from './components/Gallery';
import NotFound from './components/NotFound';
import { SaiBoutiqueProvider } from './context/saicontextapi';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <SaiBoutiqueProvider>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </SaiBoutiqueProvider>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
