import React, { createContext, useContext, useState } from 'react';

let text = false;
const SaiBoutiqueContext = createContext({
    welcome: text
});

const SaiBoutiqueProvider = ({ children }) => {
    let [welcome, setWelcome] = useState(text);
    let updateTest = (value) => {
        setWelcome(value)
    }
    
    return (
        <SaiBoutiqueContext.Provider value={{ welcome, updateTest }}>
            {children}
        </SaiBoutiqueContext.Provider>
    )
}

export { SaiBoutiqueContext, SaiBoutiqueProvider }

