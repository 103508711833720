import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Layout from './Layout';
import data from '../data/data';

const Gallery = () => {

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta name="description"
                        content="Browse our women's boutique gallery to see our latest collections and stunning outfits. Discover fashion inspiration and unique styles that celebrate individuality. Explore our carefully curated looks and find your perfect match!" />
                    <meta property="og:description" content="Browse our women's boutique gallery to see our latest collections and stunning outfits. Discover fashion inspiration and unique styles that celebrate individuality. Explore our carefully curated looks and find your perfect match!" />
                    <meta name="google-site-verification" content="i1uy9Svtz05tBx270VhlF5TiZiOb3F_rPNNMJB12EZM" />
                    <link rel="canonical" href="http://saiboutique.me/gallery" />
                    <title>Sai Boutique | Stylish Women's Clothing Boutique in Hosur</title>
                </Helmet>
            </HelmetProvider>
            <Layout>
                <div className='gallery'>
                    <div className='page-header'>
                        <div className='layout-width'>
                            <h1>
                                Here are some of the collections delivered to my esteemed clients.
                            </h1>
                        </div>
                    </div>
                    <div className='gallery-items'>
                        <div className='layout-width'>
                            {
                                data.gallery.map((item, index) => <figure key={index}><img src={require(`../images/gallery/${item}`)} alt="Sai Boutique" /></figure>)
                            }
                        </div>
                    </div>
                </div>

            </Layout>
        </>
    )
}

export default Gallery