import React from 'react'
import data from '../data/data';

const Testimonials = () => {
  return (
    <div className='testimonials'>
      <div className='layout-width'>
        <h2>Testimonials</h2>
        <div className='testimonials-cards'>
          {
            data.testimonials.map((t, index) => {
              return (
                <figure key={index}>
                  <img src={require(`../images/${t.image}.jpg`)} alt='Sai Boutique' width='150px' height='150px' />
                  <div className='testimonials-card-text'>
                    <h4>{t.name}</h4>
                    <p>{t.text}</p>
                  </div>
                </figure>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Testimonials