import React, { useContext } from 'react';
import footerLogo from '../images/footer_logo.svg';
import kcLogo from '../images/kcfreelancerme_logo.svg'
const Footer = () => {
  return (
    <footer>
      <div className='col'>
        <img src={footerLogo} alt='Sai Boutique' />
        <address>
          <p>145, Akila Garden, Bagalur Road, Hosur 635109, Tamilnadu.</p>
          <p className='copyright'>&copy; Copyright 2024. All rights reserved.</p>
        </address>
      </div>
      <div className='kc-logo'>
        <span>Site Developed By</span> <a  rel="noopener" title="KC Freelancer" href="https://www.kcfreelancer.me" target="_blank"><img src={kcLogo} alt="KC Freelancer" /></a>
        <p className='copyright'>&copy; Copyright 2024. All rights reserved.</p>
      </div>


    </footer>
  )
}

export default Footer;