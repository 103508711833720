import React from 'react';
import Layout from './Layout';
import ImageNotFound from '../images/notfound.svg'
const NotFound = () => {
    return (
        <>
            <Layout>
                <div className='notfound'>
                    <div className='page-header'>
                        <div className='layout-width'>
                            <img src={ImageNotFound} />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default NotFound