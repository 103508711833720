import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Layout from './Layout';
import Collections from './Collections'
import Testimonials from './Testimonials'


const Services = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta name="description"
            content="Explore our women's boutique services, including personalized styling, custom tailoring, and exclusive fashion collections. Experience unique pieces and expert advice to elevate your style and find the perfect outfit for any occasion!" />
          <meta property="og:description" content="Explore our women's boutique services, including personalized styling, custom tailoring, and exclusive fashion collections. Experience unique pieces and expert advice to elevate your style and find the perfect outfit for any occasion!" />
          <meta name="google-site-verification" content="i1uy9Svtz05tBx270VhlF5TiZiOb3F_rPNNMJB12EZM" />
          <link rel="canonical" href="http://saiboutique.me/services" />
          <title>Sai Boutique | Stylish Women's Clothing Boutique in Hosur</title>
        </Helmet>
      </HelmetProvider>
      <Layout>
        <div className='services'>
          <div className='page-header'>
            <div className='layout-width'>
              <h1>At <strong>Sai Boutique</strong>, <br /> I believe that true elegance starts with a perfect fit. With years of expertise and a passion for fine craft cuts, I specialize in womens tailoring that transforms your wardrobe and elevates your style.</h1>
            </div>
          </div>
          <div className='highlights services-items-wrapper'>
            <div className='layout-width'>
              <h2>I offer the following services:</h2>
              <ul className='services-items'>
                <li>Custom Suits and Dresses</li>
                <li>Normal and Designer Blouses</li>
                <li>Katori Blouse Stitches</li>
                <li>Prince Cut Blouse Stitches</li>
                <li>Chudithar Stitches</li>
                <li>Patch Work Design</li>
                <li>Fourtuck Blouse Stiches</li>
                <li>Collar Neck</li>
                <li>Pavadai Sattai Stitches</li>
              </ul>

            </div>
            <div className='tape'></div>
          </div>
          <Testimonials />
          <Collections />
        </div>
      </Layout>
    </>
  )
}

export default Services