import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Layout from './Layout';
import Header from './Header';
import Logo from './Logo';
import Collections from './Collections';
import Testimonials from './Testimonials';

const Home = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta name="description"
            content="At Sai Boutique, I believe that true elegance starts with a perfect fit. With years of expertise and a passion for fine craft cuts, I specialize in womens tailoring that transforms your wardrobe and elevates your style." />
          <meta property="og:description" content="At Sai Boutique, I believe that true elegance starts with a perfect fit. With years of expertise and a passion for fine craft cuts, I specialize in womens tailoring that transforms your wardrobe and elevates your style." />
          <meta name="google-site-verification" content="i1uy9Svtz05tBx270VhlF5TiZiOb3F_rPNNMJB12EZM" />
          <link rel="canonical" href="http://saiboutique.me/" />
          <title>Sai Boutique | Stylish Women's Clothing Boutique in Hosur</title>
        </Helmet>
      </HelmetProvider>
      <Layout>
        <div className='banner'>
          <div className='banner-container'>
            <Logo />
            <div className='circle'></div>
            <h1 className='banner-heading'>Create outfits that make you look and feel <strong>confident and vibrant professionally.</strong></h1>

          </div>
          <img src={require('../images/banner_bg3.jpg')} className='home-banner' alt='Sai Boutique' />
        </div>
        <Collections />
        <Testimonials />
      </Layout>
    </>
  )
}

export default Home