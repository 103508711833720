const data = {
    testimonials: [
        {
            name: 'Seethalakshmi',
            text: 'I am extremely satisfied with the stitched outfit created by Bhuvana. I highly recommend her and wish her all the best in her future endeavors.',
            image: 'testimonial-seethalakshmi'
        },
        {
            name: 'Praveena',
            text: 'I’m very pleased with the outfit Bhuvana stitched for me. I wholeheartedly recommend her and wish her great success in her future endeavors.',
            image: 'testimonial-praveena'
        }
    ],
    collections: [
        {
            image: 'collection_1.jpg',
            title: 'Designer Blouse'
        },
        {
            image: 'collection_2.jpg',
            title: 'Designer Blouse'
        },
        {
            image: 'collection_3.jpg',
            title: 'Collar Neck Blouse'
        },
        {
            image: 'collection_4.jpg',
            title: 'Pavadai Sattai'
        }
    ],
    menus: [
        {
            name: 'Home',
            link: '/'
        },
        {
            name: 'About',
            link: '/about'
        },
        {
            name: 'Services',
            link: '/services'
        },
        {
            name: 'Gallery',
            link: '/gallery'
        },
        {
            name: 'Contact',
            link: '/contact'
        }

    ],
    gallery : [
        "blouse_design.jpg",
        "blouse_design2.jpg",
        "blouse_design3.jpg",
        "blouse_design4.jpg",
        "blouse_design5.jpg",
        "3ccc5165f3b75b36211d1b753f23b76c.jpg",
        "3e47eef25954aa3acfd079729f8ba008.jpg",
        "98a355128b92a3da942f2e973a687675.jpg",
        "341fefcdfa41d754f1a3e1edc69f62b9.jpg",
        "690da152d746c83a22801770e26d9b4e.jpg",
        "77387b286f314ca6ed0b8639e0f4643d.jpg",
        "c3ac926a200ad5e499d2fadbaf24751a.jpg",
        "e65c7bf83dc80006230cf3dcccb598fa.jpg",
        "e6745c361d13611b2156fcf8a3fdae49.jpg"
    ]
}

export default data;