import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Layout from './Layout'
import Collections from './Collections'
import Testimonials from './Testimonials'
const About = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
        <meta name="description"
            content="Discover stunning designer blouses at Sai Boutique, expertly stitched to elevate your outfit. Shop our unique collection to find the perfect blend of style and comfort. Transform your wardrobe with our exclusive pieces today!" />
          <meta property="og:description" content="Discover stunning designer blouses at Sai Boutique, expertly stitched to elevate your outfit. Shop our unique collection to find the perfect blend of style and comfort. Transform your wardrobe with our exclusive pieces today!" />
          <meta name="google-site-verification" content="i1uy9Svtz05tBx270VhlF5TiZiOb3F_rPNNMJB12EZM" />
          <link rel="canonical" href="http://saiboutique.me/about" />
          <title>Sai Boutique | Stylish Women's Clothing Boutique in Hosur</title>
        </Helmet>
      </HelmetProvider>
      <Layout>
        <div className='about'>
          <div className='page-header'>
            <div className='layout-width'>
              <h1><strong>Hi There,</strong> <br/> I’m Bhuvana who passionate about creating outfits that make you look and feel confident and vibrant professionally.</h1>
            </div>
          </div>
          <div className='highlights'>
            <div className='layout-width'>
              <img src={require('../images/sewing.png')} alt="Sai Boutique"/>

              <h2>At <strong>Sai Boutique</strong>, I believe that true elegance starts with a perfect fit. With years of expertise and a passion for fine craft cuts, I specialize in womens tailoring that transforms your wardrobe and elevates your style.</h2>

            </div>
            <div className='tape'></div>
          </div>
          <Testimonials />
          <Collections />
        </div>
      </Layout>
    </>
  )
}

export default About