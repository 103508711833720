import React from 'react';
import logoModal from '../images/logomodel.svg'
import logoSaiBoutique from '../images/sailogo.svg'

const Logo = () => {
    return (
        <div className='logo'>
            <img src={logoModal} className='logo-model' alt="Sai Boutique" />
            <img src={logoSaiBoutique} className='logo-saiboutique' alt="Sai Boutique" />
            
        </div>
    )
}

export default Logo